@font-face {
  font-family: "Azonix";
  src: url('./fonts/azonix.otf')
}

@font-face {
  font-family: "Consolas";
  src: url('./fonts/consola.ttf')
}

@font-face {
  font-family: "Spartan";
  src: url('./fonts/spartan.ttf')
}

@font-face {
  font-family: "Tahoma";
  src: url('./fonts/consola.ttf')
}

@keyframes fadeIn {
  0% { opacity: 0.0; background-color: #000016; }
100% { opacity: 1.0; }
}

:root{
  --background-Color: rbga(16, 0, 0, 1.0);
}

img {
  opacity: 1.00;
}

img:hover {
  opacity: 1.00;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}

.bodyClass {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height:100%;
  overflow: hidden;
}

.divHeader{
  background: rgb(36, 0, 0);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 105px;
  opacity: 0.25
}

.divMain {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.extraPadding {
  margin-left: 30px;
}

.footerPadding {
  height: 175px;
}

.headerHeight {
  height: 575px;
}

.logo {
  position: absolute;
  top: 0px;
  left: 20px;
  width: 100px;
}

.logoWidth {
  width: 100px;
}

.portal {
  background-color: #160000;
  background-image: url('https://cdn.alex-souza.com/media/images/dmfi/portal1920.png');
  animation: fadeIn 1250ms;
}

.projectImageIn {
  opacity: 1.00;
  animation: imgFadeIn 500ms;
}

.projectImageOut {
  opacity: 0.65;
  animation: imgFadeOut 500ms;
}

.projectPadding {
  padding-left: 0px;
}

.projectWidth {
  width: 80px;
}

.portalVideo {
  animation-delay: 1250ms;
  animation: fadeIn 250ms;
}

.portalVideoWidth {
  width: 100%; 
}

.titleText {
  font-family: 'Azonix';
  font-size: 54px;
  font-weight: 700;
  color: whitesmoke;
  text-align: center;
}

.submitButton
{
  background-color:#aa0000;
  border-radius: 10px;
  font-family: 'Spartan';
  color: whitesmoke;
  border-width: 1px;
  border-color: #aa0000;
  text-decoration: none;
  width: 128px;
  height: 32px;
}

.submitButton:hover
{
  background-color:#800000;
}

.subTitleText {
  font-family: 'Spartan';
  font-size: 20px;
  color: whitesmoke;
  text-align: center;
}

/******************************************************* Scaling Max Width 1366 *******************************************************/
@media only screen and (min-width: 1366px) {

  .footerPadding {
      height: 210px;
  }

  .headerHeight {
    height: 400px;
  }

  .logo {
    top: 12px;
  }

  .logoWidth {
      width: 80px;
  }

  .projectHeight {
    height: 110px; 
}

  .projectWidth {
      width: 100px;
  }

  .portal {
      background-image: url('https://cdn.alex-souza.com/media/images/dmfi/portal1366.png');
  }

  .subTitleText {
      font-size: 20px;
  }

  .titlePadding {
      margin-left: 30px;
  }

  .titleText {
      font-size: 30px;
      font-weight: 700;
  }
}

/******************************************************* Scaling Max Width 1536 *******************************************************/
@media only screen and (min-width: 1536px) {

  .footerPadding {
      height: 120px;
  }

  .headerHeight {
    height: 380px;
  }

  .logo {
    top: 5px;
  }

  .logoWidth {
      width: 95px;
  }

  .projectHeight {
      height: 95px; 
  }

  .projectWidth {
      width: 128px;
  }

  .portal {
      background-image: url('https://cdn.alex-souza.com/media/images/dmfi/portal1536.png');
  }

  .subTitleText {
      font-size: 18px;
  }

  .titlePadding {
      margin-left: 45px;
      margin-top: 100px;
  }

  .titleText {
      font-size: 30px;
  }
}

/******************************************************* Scaling Max Width 1920 *******************************************************/
@media only screen and (min-width: 1920px) {

  .footerPadding {
      height: 175px;
  }

  .headerHeight {
    height: 575px;
  }

  .logo {
    top: 3px;
  }

  .logoWidth {
      width: 100px;
  }

  .projectHeight {
      height: 30px; 
  }

  .projectWidth {
      width: 150px;
  }

  .portal {
      background-image: url('https://cdn.alex-souza.com/media/images/dmfi/portal1920.png');
  }

  .subTitleText {
      font-size: 20px;
  }

  .titlePadding {
      margin-left: 50px;
  }

  .titleText {
      font-size: 54px;
      font-weight: 700;
  }
}